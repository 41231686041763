<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Monitor de Servicios</h4>
            <div class="small text-muted">Visualiza todos los servicios a clientes</div>
          </b-col>
          
          <b-col sm="5">            
            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>

      <b-tabs class="mt-3">
        <b-tab title="Servicios" active>

          <b-card header-tag="header" footer-tag="footer">
            <b-row v-if="table.mostrarFiltros">
              <b-col sm="2">
                <v-select :options="arr.filter.customers" v-model="filter.customers" placeholder="Clientes" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>               
              <b-col sm="2">
                <v-select :options="arr.filter.services" v-model="filter.services" placeholder="Servicios" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>            
              <b-col sm="2">
                <v-select :options="arr.filter.status" v-model="filter.status" placeholder="Estado" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>              
              <b-col sm="2">
                <b-button variant="outline-dark" @click="filterServicesCustomersDetails()">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>                         
            </b-row>

            <b-row>
              <b-col>

                <b-table class="mt-2 mb-0"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :current-page="table.currentPage"
                        :per-page="table.perPage" 
                        :busy="table.isBusy"                       
                        :items="table.items"
                        :fields="table.fields"
                        v-if="table.items || table.isBusy"
                        foot>  

                    <template v-slot:table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"                    
                        :key="field.key"
                        :style="{ width: field.width }"
                      >
                    </template> 

                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>

                    <template v-slot:cell(customers)="data">                  
                      <div v-if="data.item.service_customer.customer_id">             
                        <b-avatar :src="data.item.service_customer.customer.image"
                                  v-if="data.item.service_customer.customer.image">
                        </b-avatar>
                        <b-avatar v-else 
                                  icon="building"                                            
                                  variant="dark">
                        </b-avatar>     
                        &nbsp;<b>{{data.item.service_customer.customer.name}}</b>      
                        <b-icon icon="circle-fill" v-if="!data.item.service_customer.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                           
                      </div>
                    </template>                

                    <template v-slot:cell(services)="data">                            
                      {{data.item.service.name}}

                      <span v-if="data.item.service.mode=='days' || data.item.service.mode=='months' || data.item.service.mode=='years'">
                        <b-avatar v-if="data.item.service.automatic_renewal" 
                                  variant="secondary" 
                                  size="md"
                                  :title="'Renovación Automática cada ' + data.item.service.renewal + ' ' + getModeName(data.item.service.mode)"
                                  class="align-baseline display-services-legend-renewal"
                                  :text="data.item.service.renewal.toString()"
                                  badge-variant="success"
                                  badge="A">
                        </b-avatar>
                        <b-avatar v-else 
                                  variant="secondary" 
                                  size="md"
                                  :title="'Renovación Manual cada ' + data.item.service.renewal + ' ' + getModeName(data.item.service.mode)"
                                  class="align-baseline display-services-legend-renewal"
                                  :text="data.item.service.renewal.toString()"
                                  badge-variant="danger"
                                  badge="M">
                        </b-avatar>                     
                      </span>
                    </template>                

                    <template v-slot:cell(date_init)="data">  
                      {{moment(data.item.date_init).format('DD/MM/YYYY')}}
                    </template>  

                    <template v-slot:cell(date_end)="data">  
                      <div v-if="data.item.date_end">
                        {{moment(data.item.date_end).format('DD/MM/YYYY')}}
                      </div>
                    </template>  

                    <template v-slot:cell(list)="data">     
                      <span v-if="data.item.price_list">
                        {{data.item.price_list.reference}}
                      </span>
                    </template>  

                    <template v-slot:cell(quantity)="data">                                  
                      <div v-if="data.item.service.mode=='hours'">
                        {{data.item.quantity}} {{data.item.service.mode}}
                      </div>                          
                    </template>                                                      

                    <template v-slot:cell(price)="data">   
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.price_list.currency.code}).format(data.item.price)}}
                    </template>

                    <template v-slot:cell(total)="data">                                                      
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.price_list.currency.code}).format(getTotalService(data.item))}}
                    </template>

                    <template v-slot:cell(date_renewal)="data">  
                      <div v-if="data.item.canceled">
                        <b-badge variant="danger">FINALIZADO</b-badge>
                        <div class="crud-services-customers-reasons-canceled">
                          <b-icon icon="exclamation-circle" 
                                  v-if="data.item.canceled" 
                                  class="h4 mb-0" 
                                  v-b-tooltip.hover 
                                  :title="data.item.reasons_canceled" />                           
                        </div>                       
                      </div>

                      <div v-if="!data.item.canceled" class="pl-2 pr-2">
                        <div v-if="daysForRenewal(data.item)<1"
                              class="crud-services-vto-danger">                                 
                              {{moment(data.item.date_renewal).format('DD/MM/YYYY')}} 
                              (Vencido por {{daysVtoRenewal(data.item)}} día/s)
                        </div>
                        <div v-else>
                          <div v-if="data.item.date_renewal" 
                              :class="[daysForRenewal(data.item)<5 ? 'crud-services-vto-warning' : 'crud-services-vto-success']">
                            {{moment(data.item.date_renewal).format('DD/MM/YYYY')}} 
                            <b>(<span v-html="daysForRenewal(data.item)"></span> día/s)</b>
                          </div>
                        </div>
                        
                        <div v-if="data.item.project_id">
                          <b-link @click="goProject(data.item)"> 
                            <b>
                              <b-icon icon="list-task"></b-icon> Ver Proyecto
                            </b>
                          </b-link>
                        </div>
                      </div>
                    </template>  

                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right 
                                  text="Acción" 
                                  size="sm" 
                                  variant="outline-dark" 
                                  class="pull-right">
                                            
                        <b-dropdown-item @click="openRenewal(data.item)" v-if="showRenewal(data.item)">
                          <b-icon icon="arrow-repeat"></b-icon> Renovar Servicio 
                        </b-dropdown-item>                     

                        <b-dropdown-item @click="openCancel(data.item)" v-if="showCanceled(data.item)">
                          <b-icon icon="dash-circle-fill"></b-icon> Cancelar Servicio 
                        </b-dropdown-item>   
                        <b-dropdown-item @click="restoreServoceCancel(data.item)" v-else>
                          <b-icon icon="arrow-bar-left"></b-icon> Restaurar Servicio 
                        </b-dropdown-item> 

                        <b-dropdown-item @click="openActivity(data.item)">
                          <b-icon icon="file-ruled-fill"></b-icon> Ver Actividad 
                        </b-dropdown-item>                     

                      </b-dropdown>
                    </template>   

                    <template slot="bottom-row">
                      <td colspan="7" class="display-service-customers-table-foot-legend">
                        Total
                      </td>
                      <td class="display-service-customers-table-foot-total">
                        <div v-if="getTotal" v-html="this.getTotal"></div>
                      </td>
                      <td colspan="2" class="display-service-customers-table-foot-legend"></td>
                    </template>                               
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>   
              </b-col>
            </b-row>
          
            <b-row class="mt-3">              
              <b-col>
                <nav>
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="getRowCount(table.items)"
                                :per-page="table.perPage"
                                v-model="table.currentPage" />
                </nav>
              </b-col>          
            </b-row>
          </b-card>        

        </b-tab>
        <b-tab title="Historial" @click="loadServicesCustomersActivitys()">          
          <Pivot :dataJson="pivot.data" v-if="pivot.data"/> 
        </b-tab>
      </b-tabs>   

      <b-card header-tag="header" footer-tag="footer" class="mt-3">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

        </b-row>
      </b-card>   

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD RENOVACION -->
      <b-modal v-model="modal.renewal.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="md">
        <div slot="modal-header">
          {{this.modal.renewal.title}}
        </div>

        <b-row>
          <b-col lg="6">            
            <div v-if="crud.renewal.service">
              <b>{{this.crud.renewal.service.name}}</b>
              <br>
              {{this.crud.renewal.name}}
            </div>
          </b-col>          
          <b-col lg="6">            
            <div v-if="crud.renewal.customer">
              <div v-if="crud.renewal.customer.id">             
                <b-avatar :src="crud.renewal.customer.image"
                          v-if="crud.renewal.customer.image">
                </b-avatar>
                <b-avatar v-else 
                          icon="building"                                            
                          variant="dark">
                </b-avatar>     
                &nbsp;<b>{{crud.renewal.customer.name}}</b>      
                <b-icon icon="circle-fill" v-if="!crud.renewal.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                           
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col lg="6">
            <b-form-group label="Fecha Renovación">                
              <b-form-datepicker size="sm" v-model="crud.renewal.date_renewal" placeholder="Fecha" local="es" disabled></b-form-datepicker>                      
            </b-form-group>            
          </b-col>      
          <b-col lg="6">
            <b-form-group label="Próxima Renovación">                
              <b-form-datepicker size="sm" v-model="crud.renewal.date_next_renewal" placeholder="Fecha" local="es"></b-form-datepicker>                      
            </b-form-group>            
          </b-col>                    
        </b-row>

        <b-row class="mt-3">
          <b-col md="6">
            <legend class="display-services-customer-form-renewal-period-legend">
              Período de Renovación
            </legend>
            <span>CADA <u>{{this.crud.renewal.period}}</u></span>
          </b-col>
          <b-col md="6">
            <b-form-group label="Importe">
              <b-input-group :prepend="crud.renewal.currency">
                <b-form-input type="number"
                              v-model="crud.renewal.price"
                              step="0.01"                          
                              placeholder="Ingresar el precio">
                </b-form-input>
              </b-input-group>
            </b-form-group>            
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.renewal.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveRenewal()">Aceptar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD ACTIVIDAD -->
      <b-modal v-model="modal.activity.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="md">
        <div slot="modal-header">
          {{this.modal.activity.title}}
        </div>

        <b-row>
          <b-col lg="6">            
            <div v-if="arr.servicesCustomersActivitys.service">
              <b>{{this.arr.servicesCustomersActivitys.service.name}}</b>
              <br>
              {{this.arr.servicesCustomersActivitys.name}}
            </div>
          </b-col>          
          <b-col lg="6">            
            <div v-if="arr.servicesCustomersActivitys.customer">
              <div v-if="arr.servicesCustomersActivitys.customer.id">             
                <b-avatar :src="arr.servicesCustomersActivitys.customer.image"
                          v-if="arr.servicesCustomersActivitys.customer.image">
                </b-avatar>
                <b-avatar v-else 
                          icon="building"                                            
                          variant="dark">
                </b-avatar>     
                &nbsp;<b>{{arr.servicesCustomersActivitys.customer.name}}</b>      
                <b-icon icon="circle-fill" v-if="!arr.servicesCustomersActivitys.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                           
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <b-table class="mt-2 mb-0 display-services-customer-table-activity"
              responsive
              head-variant="dark"
              :hover="true"
              :small="true"
              :fixed="false"                              
              :items="tableActivity.items"
              :fields="tableActivity.fields"
              v-if="tableActivity.items">  

                <template v-slot:cell(date_init)="data">  
                  {{moment(data.item.date_init).format('DD/MM/YYYY')}}
                </template>  

                <template v-slot:cell(date_end)="data">  
                  <div v-if="data.item.date_end">
                    {{moment(data.item.date_end).format('DD/MM/YYYY')}}
                  </div>
                </template>  

                <template v-slot:cell(price)="data">   
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.currency}).format(data.item.price)}}
                </template>

                <template v-slot:cell(f_action)="data">                                       
                  <b-dropdown right   
                              text="Acción" 
                              size="sm" 
                              variant="outline-dark" 
                              class="pull-right"
                              v-if="!arr.servicesCustomersActivitys.canceled">                    
                    <b-dropdown-item @click="removeActivity(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>

            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>   
          </b-col>          
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.activity.active=false">Cancelar</b-button>
        </div>
      </b-modal>

      <!-- CRUD CANCEL -->
      <b-modal v-model="modal.cancel.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="md">
        <div slot="modal-header">
          {{this.modal.cancel.title}}
        </div>

        <b-row>
          <b-col lg="12">
            <b-form-group label="Fecha Finalización">                
              <b-form-datepicker size="sm" v-model="crud.cancel.date_end" placeholder="Fecha" local="es"></b-form-datepicker>                      
            </b-form-group>            
          </b-col>
          <b-col lg="12">
            <b-form-group label="Motivos de Cancelación">
              <b-form-textarea                
                v-model="crud.cancel.reasons_canceled"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.cancel.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveCancel()">Aceptar</b-button>          
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Param from '@/config/parameters'
  import moment from 'moment'
  import Storage from '@/handler/storageLocal'
  import Pivot from '@/components/inc/pivot/index'

  export default {
    components: { 
      Pivot 
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.SERVICIOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'displayServicesCustomers',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'customers', label: 'Cliente', class:"align-middle", width:"150px"},            
            {key: 'services', label: 'Servicio', class:"align-middle", width:"150px"},                    
            {key: 'date_init', label: 'Inicio', class:"align-middle text-center", width:"100px"},            
            {key: 'date_end', label: 'Fin', class:"align-middle text-center", width:"100px"},            
            {key: 'list', label: 'Lista', class:"align-middle text-center", width:"80px"},
            {key: 'quantity', label: 'Cantidad', class:"align-middle text-center", width:"100px"},            
            {key: 'price', label: 'Importe', class:"align-middle text-right", width:"100px"},     
            {key: 'total', label: 'Total', class:"align-middle text-right", width:"100px"},     
            {key: 'date_renewal', label: '', class:"align-middle text-center", width:"160px"},
            {key: 'f_action', label:'', class:"align-middle", width:"80px"},
          ],
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 50,
          isBusy: false,          
        },         
        tableActivity: {
          items: [],
          fields: [
            {key: 'date_end', label: 'Renovación', class:"align-middle text-left", width:"100px"},                        
            {key: 'date_init', label: 'Inicio', class:"align-middle text-left", width:"100px"},                        
            {key: 'price', label: 'Total', class:"align-middle text-right", width:"100px"},                 
            {key: 'f_action', label:'', class:"align-middle", width:"80px"},
          ],
        } ,       
        crud: {        
          renewal: {
            id: 0,
            date_renewal: '',
            date_next_renewal: '',
            name: '',
            customer: null,
            service: null,
            period: '',
            currency: '',
            price: 0,
          },
          cancel: {
            id: 0,
            reasons_canceled: '',
            date_end: ''
          }            
        },            
        modal: {
          renewal: {
            active: false,
            title: ''
          },
          activity: {
            active: false,
            title: ''
          },
          cancel: {
            active: false,
            title: ''
          },            
        },  
        arr: {
          servicesCustomersDetails: [],
          servicesCustomersActivitys: {
            name: '',
            customer: null,
            service: null,
            canceled: false,
          },
          filter : {
            services: [],
            customers: [],
            status: [
              {code:false, label: 'Activo'},
              {code:true, label: 'Finalizado'},                
            ],
          }
        },
        filter: {
          services: [],
          customers: [],
          status: [
            {code:false, label: 'Activo'}
          ],
        },           
        pivot: {
          data: null,          
        },     
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {     
      this.filterLoad() 
      this.filterServicesCustomersDetails()      
    },    
    computed: {
      getTotal() {
        //obtengo las diferentes monedas
        var currency = []
        this.arr.servicesCustomersDetails.forEach(element => {
          if(element.service.list) {
            if(element.service.list.currency.code) {
              var code = element.service.list.currency.code
              if(!currency.includes(code)) {
                currency[code] = 0
              }
            }
          }                    
        })
        
        // sumo totales por monedas
        this.arr.servicesCustomersDetails.forEach(element => {
          if(element.service.list) {
            if(element.service.list.currency.code) {
              var code = element.service.list.currency.code

              if(element.quantity) {
                currency[code] = currency[code] + (parseFloat(element.price) * parseFloat(element.quantity))
              } else {
                currency[code] = currency[code] + parseFloat(element.price)
              }

            }
          }          
        });   
        
        //armo html para mostrar total                
        var htmlResult = ''
        if(currency) {          
          for(let prop of Object.keys(currency)) {
            htmlResult = htmlResult + Intl.NumberFormat('es-AR',{style:'currency',currency:prop}).format(currency[prop]) + '<br>'
          }          
        }
        return htmlResult        
      },     
    },
    methods: {
      getRowCount (items) {
        return items.length
      },    
      load() {
        var result = serviceAPI.obtenerServicesCustomersDetails()

        result.then((response) => {
          var data = response.data
          this.table.items = data             
          
          this.arr.servicesCustomersDetails = data
          
          this.table.isBusy = false
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
  
      filterLoad() {
        this.filterLoadCustomers()        
        this.filterLoadServices()

        this.restoreFilterStorage() 
      },
      filterLoadServices() {
        var result = serviceAPI.obtenerServices()        
        result.then((response) => {
          var data = response.data    

          this.arr.filter.services = []
          data.forEach(element => {            
            this.arr.filter.services.push({ code: element.id, label: element.name })            
          });          
        })   
      },      
      filterLoadCustomers() {
        var result = serviceAPI.obtenerClientes()        
        result.then((response) => {
          var data = response.data    

          this.arr.filter.customers = []
          data.forEach(element => {            
            this.arr.filter.customers.push({ code: element.id, label: element.name })            
          });          
        })   
      },
      filterServicesCustomersDetails(){    
        this.saveFilterStorage()

        this.table.isBusy = true 
   
        var result = serviceAPI.filtrarServiceCustomersDetails(this.filter)        
        result.then((response) => {     
          var data = response.data
          this.table.items = data
          this.arr.servicesCustomersDetails = data
          
          this.table.isBusy = false
        })
        .catch(error => {             
          this.table.isBusy = false          
          this.$awn.alert(Error.showError(error))
        });          
      },  
      
      openRenewal(item) {             
        this.crud.renewal.id = item.id
        this.crud.renewal.date_renewal = item.date_renewal       
        this.crud.renewal.customer = item.service_customer.customer
        this.crud.renewal.service = item.service
        this.crud.renewal.name = item.service_customer.name
        this.crud.renewal.period = item.service.renewal + ' ' + this.getModeName(item.service.mode)        
        this.crud.renewal.currency = item.service.list.currency.symbol
        this.crud.renewal.price = item.service.price_simple
        
        switch (item.service.mode) {
          case 'days':
            this.crud.renewal.date_next_renewal = moment(item.date_renewal).add(item.service.renewal, 'days').format('YYYY-MM-DD')
            break;
        
          case 'months':
            this.crud.renewal.date_next_renewal = moment(item.date_renewal).add(item.service.renewal, 'months').format('YYYY-MM-DD')
            break;

          case 'years':
            this.crud.renewal.date_next_renewal = moment(item.date_renewal).add(item.service.renewal, 'years').format('YYYY-MM-DD')
            break;            
        }
        
        this.modal.renewal.active = true
        this.modal.renewal.title = "Renovar Servicio"
      },
      saveRenewal() {
        this.$bvModal.msgBoxConfirm('¿Desea renovar el servicio?', {
          title: 'Renovar Servicio',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'RENOVAR',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {                
            let loader = this.$loading.show();
            var result = serviceAPI.renewalServicesCustomersDetails(this.crud.renewal);

            result.then((response) => {      
              this.modal.renewal.active = false        
              loader.hide()
              this.filterServicesCustomersDetails()
              this.$awn.success("Servicio Renovado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }  
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })          
      },
      
      openActivity(item) {                
        this.tableActivity.items = item.activitys.reverse()
        this.arr.servicesCustomersActivitys.customer = item.service_customer.customer
        this.arr.servicesCustomersActivitys.service = item.service
        this.arr.servicesCustomersActivitys.name = item.service_customer.name
        this.arr.servicesCustomersActivitys.canceled = item.canceled
                
        this.modal.activity.active = true
        this.modal.activity.title = "Actividad del servicio"
      },
      removeActivity(item) {        
        var service = 'servicio ' + item.service.name        
        var customer = 'cliente ' + item.customer.name        
        var period = 'periodo ' + moment(item.date_init).format('DD/MM/YYYY') + ' - ' + moment(item.date_end).format('DD/MM/YYYY')

        this.$bvModal.msgBoxConfirm('¿Desea cancelar el ' + period + ' correspondiente al ' + service + ' asignado al ' + customer + '?', {
          title: 'Cancelar Actividad',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();            

            var result = serviceAPI.eliminarServicesCustomersActivity(item.id);

            result.then((response) => {      
              loader.hide()
              this.filterServicesCustomersDetails()
              this.$awn.success("Registro eliminado");
              this.modal.activity.active = false
            })
            .catch(error => {              
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          } 
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })
      },

      daysForRenewal(item) {        
        var date1 = moment();
        var date2 = moment(item.date_renewal);

        return date2.diff(date1, 'days') + 1;
      },
      daysVtoRenewal(item) {        
        var date1 = moment(item.date_renewal);
        var date2 = moment();

        return date2.diff(date1, 'days') + 1;
      },        
      getTotalService(item) {
        if(item.quantity) {
          return item.quantity * item.price
        } else {
          return item.price
        }
      },

      showRenewal(item) {
        return (item.service.mode=='days' || 
                item.service.mode=='months' || 
                item.service.mode=='years') && 
                !item.service.automatic_renewal &&
                !item.canceled        
      },
      showCanceled(item) {        
        if( !item.canceled && 
            (item.service.mode!='units' && item.service.mode!='hours') ) {
          return true
        } else {
          return false
        }
      },

      openCancel(item) {
        this.crud.cancel.id = item.id
        this.crud.cancel.date_end = moment().format('YYYY-MM-DD')
        this.crud.cancel.reasons_canceled = ''
        
        this.modal.cancel.active = true
        this.modal.cancel.title = "Cancelar Servicio"
      },
      saveCancel() {
        this.$bvModal.msgBoxConfirm('¿Desea cancelar el servicio?', {
          title: 'Cancelar Servicios',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'ACEPTAR',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {      
            let loader = this.$loading.show();
            var result = serviceAPI.cancelarServicesCustomersDetails(this.crud.cancel);

            result.then((response) => {      
              this.modal.cancel.active = false        
              loader.hide()
              this.filterServicesCustomersDetails()
              this.$awn.success("Servicio Cancelado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }  
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })          
      },
      restoreServoceCancel(item) {
        this.$bvModal.msgBoxConfirm('¿Desea restaurar el servicio?', {
          title: 'Restaurar Servicios',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'info',
          okTitle: 'RESTAURAR',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'info',
          headerTextVariant: 'black',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {      
            let loader = this.$loading.show();            
            
            this.crud.cancel.id = item.id            
            var result = serviceAPI.restaurarServicesCustomersDetails(this.crud.cancel);

            result.then((response) => {      
              this.modal.cancel.active = false        
              loader.hide()
              this.filterServicesCustomersDetails()
              this.$awn.success("Servicio Restaurado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }  
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })          
      },   

      getModeName(mode) {
        switch (mode) {
          case 'units':
            return 'UNIDAD/ES'
            break;
          
          case 'hours':
            return 'HORA/S'
            break;

          case 'days':
            return 'DÍA/S'
            break;

          case 'months':
            return 'MES/ES'
            break;

          case 'years':
            return 'AÑO/S'
            break;            
        }
      },

      // PIVOT
      loadServicesCustomersActivitys() {                
        var result = serviceAPI.obtenerServicesCustomersActivity()        
        result.then((response) => {
          var data = response.data
          this.pivot.data = this.processPivot(data)
        })                   
      },      
      processPivot(data) {        
        var addData = []        
        data.forEach(element => {          
          addData.push({
            date: element.date,
            customer: (element.customer) ? element.customer.name : '',
            category_service: (element.service.category) ? element.service.category.name : '',
            service: (element.service) ? element.service.name : '',
            currency: element.currency,
            price: element.price,                        
          })
        });

        return {
          dataSource: {                  
            data: addData,
          },
          slice: {
            rows: [
              {
                uniqueName: "category_service",
                sort: "asc",
              },                 
              {
                uniqueName: "service",
                sort: "asc",
              },              
              {
                uniqueName: "customer",
                sort: "asc",
              },                            
            ],
            columns: [
              {
                uniqueName: "currency",
                sort: "asc",
              },                 
              {
                uniqueName: "date.Year",
                sort: "asc",
              },                  
              {
                uniqueName: "date.Month",
                sort: "asc",
              },                                                                  
            ],
            measures: [
              {
                uniqueName: "price",
                aggregation: "sum"
              },                                   
            ],
            expands: {
              expandAll: false,
              rows: [],
              columns: []
            },
            drills: {
              drillAll: false
            }
          },
          options: {
            grid: {
              type: "compact",
              title: "",
              showFilter: true,
              showHeaders: true,
              showTotals: false,
              showGrandTotals: "off",
              showHierarchies: true,
              showHierarchyCaptions: true,
              showReportFiltersArea: true
            },
            configuratorActive: false,
            configuratorButton: true,
            showAggregations: true,
            showCalculatedValuesButton: true,
            drillThrough: true,
            showDrillThroughConfigurator: true,
            sorting: "on",
            datePattern: "dd/MM/yyyy",
            dateTimePattern: "dd/MM/yyyy HH:mm:ss",
            saveAllFormats: false,
            showDefaultSlice: true,
            defaultHierarchySortName: "asc"
          },
          formats: [
            {
              name: "",
              thousandsSeparator: " ",
              decimalSeparator: ".",
              decimalPlaces: 2,
              maxSymbols: 20,
              currencySymbol: "",
              currencySymbolAlign: "left",
              nullValue: " ",
              infinityValue: "Infinity",
              divideByZeroValue: "Infinity"
            }
          ],
          localization: "https://cdn.webdatarocks.com/loc/es.json"                     
        }    
      },       

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_display_services_customers', JSON.stringify(this.filter))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_display_services_customers')) {
          this.filter = JSON.parse(Storage.getValue('filter_display_services_customers'))
        }         
      },

      goProject(item) {
        this.$router.push({ name: 'ProjectStaffDetailHoursProjects', params: { projectID: item.project_id } })                  
      }
    }    
  }
</script>
<style scoped>
  .crud-services-customers-reasons-canceled {
    cursor: pointer;
  }
  .crud-services-vto-warning {
    background: goldenrod;
    font-weight: bold;
    color: white;
  }
  .crud-services-vto-success {
    background: darkgreen;
    font-weight: bold;
    color: white;
  }  
  .crud-services-vto-danger {
    background: darkred;
    font-weight: bold;
    color: white;
  }      
  .display-service-customers-table-foot-legend {
    background: var(--gray-dark);
    color: #fff;
    font-weight: 600;
    text-align: right;
    padding-right: 10px;
    font-size: 16px;
    vertical-align: middle;
  }
  .display-service-customers-table-foot-total {
    background: var(--gray-dark);
    color: #fff;
    font-weight: 600;
    text-align: right;
    padding-right: 10px;
    font-size: 16px;
  }  
  .display-services-customer-legend {
    cursor: pointer;
  }
  .display-services-customer-form-renewal-period-legend {
    font-size: 14px;
  }
  .display-services-legend-renewal {
    cursor: pointer;
  }  
  .display-services-customer-table-activity {
    min-height: 100px;
  }  
</style>